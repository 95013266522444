<template functional>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66667 8.61198C5.27604 8.61198 4.06771 7.82292 3.46354 6.66667H6.66667V5.55469H3.10156C3.07292 5.375 3.05469 5.1875 3.05469 5C3.05469 4.8125 3.07292 4.625 3.10156 4.44531H6.66667V3.33333H3.46354C4.0651 2.17708 5.27083 1.38802 6.66667 1.38802C7.5651 1.38802 8.38281 1.71615 9.01302 2.25781L10 1.27865C9.11719 0.486979 7.94792 0 6.66667 0C4.49219 0 2.64323 1.39062 1.95833 3.33333H0V4.44531H1.70052C1.67708 4.6276 1.66667 4.8125 1.66667 5C1.66667 5.1875 1.67708 5.3724 1.70052 5.55469H0V6.66667H1.95833C2.64323 8.60938 4.49219 10 6.66667 10C7.94792 10 9.11719 9.51302 10 8.72135L9.01302 7.73698C8.38281 8.28125 7.5651 8.61198 6.66667 8.61198Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

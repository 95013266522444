<template functional>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.85562 6.00048C9.61959 6.00048 9.42624 6.19131 9.42624 6.42986V10.7161C9.42624 10.9521 9.2354 11.1455 8.99686 11.1455H1.28562C1.04959 11.1455 0.856246 10.9547 0.856246 10.7161V2.1436C0.856246 1.90757 1.04708 1.71423 1.28562 1.71423H6.42812C6.66416 1.71423 6.8575 1.52339 6.8575 1.28485C6.8575 1.0463 6.66667 0.855469 6.42812 0.855469H1.28562C0.575016 0.85798 0 1.43551 0 2.1436V10.7136C0 11.4242 0.575016 11.9992 1.28562 11.9992H8.99937C9.70998 11.9992 10.285 11.4242 10.285 10.7136V6.42986C10.285 6.19382 10.0942 6.00048 9.85562 6.00048Z"
      :fill="props.fillColor"
    />
    <path d="M3.35938 7.04801V8.64003H4.95139L9.64983 3.94358L8.05782 2.35156L3.35938 7.04801Z" :fill="props.fillColor" />
    <path
      d="M10.8759 2.1169L9.88491 1.12388C9.71974 0.958707 9.4491 0.958707 9.28392 1.12388L8.50781 1.89999L10.0998 3.492L10.8759 2.71589C11.0431 2.55072 11.0431 2.28207 10.8759 2.1169Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2AF3F3',
    },
  },
};
</script>

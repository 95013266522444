<template functional>
  <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5288_53)">
      <path
        d="M6.15037 4.90655H3.3237C3.2437 4.90655 3.21704 4.87988 3.21704 4.79988V3.27988C3.21704 3.19988 3.2437 3.14655 3.3237 3.14655H7.03037C7.1637 3.14655 7.29704 3.17322 7.4037 3.25322C7.53704 3.30655 7.6437 3.38655 7.75037 3.49322C7.85704 3.59988 7.93704 3.73322 7.99037 3.86655C8.0437 3.99988 8.09704 4.13322 8.09704 4.26655V6.10655C8.09704 6.23988 8.07037 6.37322 7.99037 6.50655C7.93704 6.63988 7.85704 6.74655 7.75037 6.85322C7.6437 6.95989 7.53704 7.03989 7.4037 7.09322C7.27037 7.14655 7.1637 7.17322 7.03037 7.17322H1.43037C1.29704 7.17322 1.1637 7.14655 1.03037 7.09322C0.897036 7.03989 0.790369 6.93322 0.683703 6.85322C0.577036 6.74655 0.497036 6.63988 0.443703 6.50655C0.390369 6.37322 0.337036 6.23988 0.337036 6.10655V1.97322C0.337036 1.83988 0.363703 1.70655 0.443703 1.57322C0.523703 1.43988 0.577036 1.33322 0.683703 1.22655C0.790369 1.11988 0.897036 1.03988 1.03037 0.98655C1.1637 0.933216 1.29704 0.879883 1.43037 0.879883H8.01704C8.09704 0.879883 8.1237 0.906549 8.1237 0.98655V2.63988C8.1237 2.71988 8.09704 2.74655 8.01704 2.74655H2.33704V5.33322H6.15037V4.90655Z"
        :fill="props.fillColor"
      />
      <path
        d="M9.69703 6.95993H11.4037V4.71993H16.337V3.27993H11.377V2.63993H16.337V1.03993H10.6304C10.5237 1.03993 10.3904 1.06659 10.2837 1.11993C10.177 1.17326 10.0704 1.25326 9.99036 1.33326C9.91036 1.41326 9.83036 1.51993 9.77703 1.65326C9.72369 1.75993 9.69703 1.89326 9.69703 1.99993V6.95993ZM11.377 7.19993H9.67036C9.51036 7.19993 9.43036 7.11993 9.43036 6.95993V1.99993C9.43036 1.83993 9.45703 1.70659 9.53703 1.57326C9.61703 1.43993 9.69703 1.30659 9.80369 1.19993C9.93703 1.06659 10.0704 0.986593 10.2037 0.906593C10.337 0.826593 10.497 0.799927 10.6304 0.799927H16.337C16.497 0.799927 16.577 0.879927 16.577 1.03993V2.63993C16.577 2.79993 16.497 2.87993 16.337 2.87993H11.617V3.06659H16.3104C16.4704 3.06659 16.5504 3.14659 16.5504 3.30659V4.74659C16.5504 4.90659 16.4704 4.98659 16.3104 4.98659H11.617V6.98659C11.617 7.11993 11.537 7.19993 11.377 7.19993Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5288_53">
        <rect width="16.24" height="6.4" fill="white" transform="translate(0.337036 0.799927)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M11.55 3.61785C10.9875 3.09285 10.2 2.30535 9.58124 1.74285L9.54374 1.7241C9.48749 1.66785 9.41249 1.63035 9.33749 1.6116C7.79999 1.3116 0.693738 -0.0196525 0.543738 -0.000902515C0.506238 -0.000902515 0.468738 0.0178475 0.449988 0.0365975L0.412488 0.0740975C0.356238 0.111597 0.337488 0.167847 0.299988 0.224097V0.242847V0.374097V0.392847C1.10624 2.6241 4.25624 9.91785 4.89374 11.6428C4.93124 11.7553 5.00624 11.9803 5.13749 11.9991H5.17499C5.24999 11.9991 5.54999 11.6053 5.54999 11.6053C5.54999 11.6053 10.9875 5.0241 11.5312 4.3116C11.6062 4.21785 11.6625 4.1241 11.7187 4.03035C11.7187 3.88035 11.6625 3.7116 11.55 3.61785ZM6.91874 4.3866L9.24374 2.4741L10.5937 3.73035L6.91874 4.3866ZM6.01874 4.25535L2.02499 0.992848L8.47499 2.19285L6.01874 4.25535ZM6.39374 5.11785L10.4812 4.4616L5.81249 10.0866L6.39374 5.11785ZM1.49999 1.3116L5.69999 4.8741L5.09999 10.0866L1.49999 1.3116Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="11.4" height="12" fill="white" transform="translate(0.299988)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

<template functional>
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.74203 3.53744C6.61203 4.05744 6.41703 4.25244 6.09203 4.18744C5.89703 4.18744 5.63703 3.79745 5.37703 3.60245C5.24703 3.47245 4.20703 4.96744 3.68703 5.48744C3.49203 5.68245 3.36203 5.29245 3.36203 4.31744C4.20703 3.47245 4.53203 3.08245 4.40203 3.01745C3.88203 2.75745 3.68703 2.56245 3.36203 2.56245C2.77703 2.56245 2.32203 4.64245 2.32203 5.81245C2.32203 6.98244 2.84203 8.67245 3.03703 8.41245L11.617 0.677445C12.787 -0.427555 13.502 0.222445 13.697 2.62745L14.022 6.78745C14.022 9.64744 13.047 9.45245 12.332 8.93245L10.512 7.69745C10.512 7.69745 9.27703 6.65745 9.27703 6.39745C9.27703 6.13745 9.60203 5.81245 9.60203 5.81245C9.92703 5.81245 9.73203 5.74745 10.967 6.98245C12.202 8.21745 12.722 7.63244 12.657 7.11244V4.18744C12.657 2.23744 12.072 1.71745 11.617 2.10745C8.82203 4.70745 3.81703 9.84244 2.77703 9.84244C2.06203 9.84244 1.54203 9.38745 1.34703 8.47745C1.08703 6.98245 0.957031 5.87744 0.957031 5.16244C0.957031 4.12244 1.08703 2.56245 1.28203 1.97745C1.47703 1.39245 1.99703 0.807445 2.38703 1.00245C2.38703 1.00245 6.93703 3.01744 6.74203 3.53744Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

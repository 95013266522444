<template functional>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.01677 4.95135V4.95031C5.95985 4.95446 5.66627 4.97204 5.01138 4.97204C4.4885 4.97204 4.12042 4.9565 3.991 4.95031V4.95185C1.97815 4.86331 0.475769 4.51285 0.475769 4.0935C0.475769 3.67469 1.97815 3.32419 3.991 3.23412V4.60292C4.1225 4.61223 4.49938 4.6345 5.02019 4.6345C5.64508 4.6345 5.95827 4.60862 6.01677 4.60342V3.23515C8.02546 3.32469 9.52423 3.67519 9.52423 4.0935C9.52423 4.51285 8.02546 4.86231 6.01677 4.95135ZM6.01677 3.09277V1.86788H8.81962V0H1.18812V1.86788H3.991V3.09227C1.71308 3.19685 0 3.64827 0 4.18877C0 4.72923 1.71308 5.18015 3.991 5.28527V9.2105H6.01677V5.28423C8.29104 5.17965 10 4.72873 10 4.18877C10 3.64881 8.29104 3.19788 6.01677 3.09277Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

<template functional>
  <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5070_77)">
      <path
        d="M5.52719 0.833008C2.60302 0.844754 0.235379 3.21239 0.223633 6.13656C0.223633 6.72218 0.318159 7.30388 0.503297 7.8593C0.641451 8.2374 1.05144 8.441 1.43626 8.3241C1.80821 8.18147 2.00565 7.77484 1.88708 7.39449C1.72991 6.96157 1.65999 6.50237 1.68068 6.0426C1.74445 3.93057 3.50801 2.26992 5.62004 2.33369C7.73206 2.39745 9.39271 4.16101 9.32895 6.27304C9.2691 8.25027 7.71193 9.8561 5.73694 9.97636C5.73694 9.97636 4.99247 10.0217 4.62219 10.067C4.48572 10.0865 4.35036 10.1134 4.21668 10.1475C4.19766 10.1671 4.1669 10.1671 4.14732 10.1481L4.14677 10.1475C4.13334 10.1302 4.13334 10.1056 4.14677 10.0882L4.26199 9.45954L4.96115 6.31499C5.04505 5.92234 4.79503 5.53584 4.40238 5.45195C4.00973 5.36805 3.62323 5.61807 3.53933 6.01072C3.53933 6.01072 1.87645 13.7076 1.87645 13.7776C1.78136 14.1517 2.00733 14.5321 2.38152 14.6272C2.38544 14.6283 2.38991 14.6294 2.39383 14.63H2.43242C2.80605 14.7267 3.18752 14.5019 3.28372 14.1283C3.2854 14.1221 3.28708 14.1159 3.2882 14.1092C3.28708 14.0975 3.28708 14.0857 3.2882 14.0746C3.30889 13.9839 3.51864 12.9598 3.51864 12.9598C3.67749 12.1868 4.30058 11.5945 5.08029 11.4748C5.24081 11.4502 5.91536 11.4049 5.91536 11.4049C8.82835 11.1146 10.9544 8.51763 10.6635 5.60464C10.3984 2.94783 8.19742 0.902365 5.52719 0.833008Z"
        :fill="props.fillColor"
      />
      <path
        d="M5.84888 13.0616C5.37063 12.962 4.903 13.2691 4.80344 13.7468C4.80232 13.7513 4.80176 13.7557 4.80064 13.7602C4.69716 14.2351 4.9981 14.7044 5.47355 14.8079C5.47411 14.8079 5.47467 14.8079 5.47523 14.8084H5.49984C5.96522 14.9209 6.43341 14.6345 6.54528 14.1697C6.5464 14.1663 6.54696 14.1624 6.54807 14.159V14.1104C6.6303 13.6304 6.32377 13.1701 5.84888 13.0616Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5070_77">
        <rect width="10.4667" height="14" fill="white" transform="translate(0.223633 0.833008)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3883_4768)">
      <path
        d="M7.56006 2.41263C7.18506 2.06263 6.66006 1.53763 6.24756 1.16263L6.22256 1.15013C6.18506 1.11263 6.13506 1.08763 6.08506 1.07513C5.06006 0.875131 0.322559 -0.0123693 0.222559 0.000130745C0.197559 0.000130745 0.172559 0.0126307 0.160059 0.0251307L0.135059 0.0501307C0.0975586 0.0751307 0.0850586 0.112631 0.0600586 0.150131V0.162631V0.250131V0.262631C0.597559 1.75013 2.69756 6.61263 3.12256 7.76263C3.14756 7.83763 3.19756 7.98763 3.28506 8.00013H3.31006C3.36006 8.00013 3.56006 7.73763 3.56006 7.73763C3.56006 7.73763 7.18506 3.35013 7.54756 2.87513C7.59756 2.81263 7.63506 2.75013 7.67256 2.68763C7.67256 2.58763 7.63506 2.47513 7.56006 2.41263ZM4.47256 2.92513L6.02256 1.65013L6.92256 2.48763L4.47256 2.92513ZM3.87256 2.83763L1.21006 0.662631L5.51006 1.46263L3.87256 2.83763ZM4.12256 3.41263L6.84756 2.97513L3.73506 6.72513L4.12256 3.41263ZM0.860059 0.875131L3.66006 3.25013L3.26006 6.72513L0.860059 0.875131Z"
        :fill="props.fillColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

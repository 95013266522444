<template functional>
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.4737 0.511111L6.38481 3.61111C6.24037 3.82222 6.51814 4.07778 6.71814 3.9L8.7737 2.11111C8.82926 2.06667 8.90703 2.1 8.90703 2.17778V7.76667C8.90703 7.84444 8.80703 7.87778 8.76259 7.82222L2.54037 0.377778C2.34037 0.133333 2.05148 0 1.72925 0H1.50703C0.929255 0 0.451477 0.477778 0.451477 1.06667V8.93333C0.451477 9.52222 0.929255 10 1.51814 10C1.88481 10 2.22925 9.81111 2.42925 9.48889L4.51814 6.38889C4.66259 6.17778 4.38481 5.92222 4.18481 6.1L2.12925 7.87778C2.0737 7.92222 1.99592 7.88889 1.99592 7.81111V2.23333C1.99592 2.15556 2.09592 2.12222 2.14037 2.17778L8.36259 9.62222C8.56259 9.86667 8.86259 10 9.1737 10H9.39592C9.98481 10 10.4626 9.52222 10.4626 8.93333V1.06667C10.4626 0.477778 9.98481 0 9.39592 0C9.01814 0 8.6737 0.188889 8.4737 0.511111Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.14444 1.73333C6.8 0.696296 5.48889 0 3.88889 0C2.81481 0 1.45926 0.388889 0.859259 1.25556L1.77407 1.88519C2.08148 1.44074 2.98889 1.10741 3.88889 1.10741C5.10741 1.10741 5.93333 1.60741 6.08889 2.07778C6.17037 2.32222 6.04815 2.57037 5.72593 2.81481C5.55185 2.94444 5.32593 3.12222 5.06667 3.32963H0V4.44074H3.69259C3.22963 4.81852 2.75556 5.2037 2.32222 5.55185H0V6.66296H0.985185C0.581482 7.14074 0.448148 7.71852 0.637037 8.27407C0.988889 9.30741 2.29259 10 3.88889 10C4.96296 10 6.31852 9.61111 6.91852 8.74444L6.0037 8.11481C5.6963 8.55926 4.78889 8.89259 3.88889 8.89259C2.67407 8.89259 1.85185 8.3963 1.69259 7.92593C1.61111 7.68148 1.72593 7.43333 2.04074 7.1963C2.24444 7.04074 2.47037 6.86296 2.71111 6.67037H7.77778V5.55926H4.08889C4.2 5.47037 5.13704 4.7037 5.45556 4.44815H7.77778V3.33333H6.7963C7.2037 2.85926 7.32963 2.29259 7.14444 1.73333Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

<template functional>
  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5288_11)">
      <path
        d="M6.05495 14C5.99721 13.9567 5.50649 13.6536 4.90031 13.4371C4.26526 13.2061 3.45701 13.134 3.45701 13.134C3.45701 13.134 2.64876 13.2061 2.01371 13.4371C1.42196 13.6536 0.931235 13.9567 0.85907 14L3.45701 11.9938L6.05495 14Z"
        :fill="props.fillColor"
      />
      <path
        d="M5.96839 11.6041C5.99725 12.4124 6.57457 13.8268 6.57457 13.8268L4.30859 12.0082C4.30859 12.0082 4.48179 11.8495 4.69828 11.532C4.91478 11.2144 4.85705 10.2041 4.85705 10.2041L4.87148 0C4.87148 0 5.34777 2.59794 5.69416 3.63711C6.04055 4.66186 6.589 5.31134 6.589 5.31134C6.589 5.31134 6.32921 5.4701 6.06942 6.09072C5.82406 6.69691 5.95395 10.7814 5.96839 11.6041Z"
        :fill="props.fillColor"
      />
      <path
        d="M3.45702 9.00624C3.34156 6.55263 3.34156 4.07016 3.45702 1.63098C3.57248 4.07016 3.57248 6.55263 3.45702 9.00624Z"
        :fill="props.fillColor"
      />
      <path
        d="M0.945692 11.6041C0.974558 10.7959 1.09002 6.69691 0.844661 6.09072C0.5993 5.48454 0.325073 5.31134 0.325073 5.31134C0.325073 5.31134 0.873527 4.66186 1.21992 3.63711C1.56631 2.59794 2.0426 0 2.0426 0V10.1897C2.0426 10.1897 1.98487 11.2 2.20136 11.5175C2.41786 11.8351 2.59105 11.9938 2.59105 11.9938L0.339506 13.8268C0.339506 13.8268 0.931259 12.4268 0.945692 11.6041Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5288_11">
        <rect width="6.26392" height="14" fill="white" transform="translate(0.325073)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.14241 6.27273C3.7583 6.72504 3.21266 6.9512 2.50548 6.9512C1.87368 6.9512 1.39444 6.76633 1.06777 6.39658C0.744695 6.02683 0.583156 5.49196 0.583156 4.79196V1.01732H1.89163V4.7758C1.89163 5.51529 2.19855 5.88504 2.8124 5.88504C3.44779 5.88504 3.87677 5.65709 4.09933 5.20119V1.01732H5.4078V6.84351H4.17472L4.14241 6.27273ZM8.35837 5.79888H11.4599V6.84351H6.73221V5.98735L9.68838 2.07271H6.78605V1.01732H11.3469V1.84655L8.35837 5.79888ZM15.9936 5.26042C15.9936 5.02708 15.8967 4.84939 15.7028 4.72734C15.5126 4.60529 15.1949 4.49759 14.7497 4.40426C14.3046 4.31093 13.9331 4.19246 13.6351 4.04887C12.9818 3.73297 12.6551 3.27528 12.6551 2.67579C12.6551 2.17322 12.8669 1.75322 13.2905 1.41578C13.7141 1.07834 14.2526 0.909624 14.9059 0.909624C15.6023 0.909624 16.1641 1.08193 16.5913 1.42655C17.0221 1.77117 17.2374 2.21809 17.2374 2.76733H15.929C15.929 2.51604 15.8356 2.30784 15.649 2.14271C15.4623 1.97399 15.2146 1.88963 14.9059 1.88963C14.6187 1.88963 14.3836 1.95604 14.2005 2.08886C14.021 2.22168 13.9313 2.39938 13.9313 2.62194C13.9313 2.82297 14.0156 2.97912 14.1843 3.09041C14.3531 3.20169 14.6941 3.31477 15.2074 3.42964C15.7208 3.54092 16.1228 3.67554 16.4136 3.83349C16.708 3.98785 16.9251 4.17452 17.0651 4.39349C17.2087 4.61247 17.2805 4.87811 17.2805 5.19042C17.2805 5.71452 17.0633 6.13991 16.629 6.46658C16.1946 6.78966 15.6256 6.9512 14.922 6.9512C14.4446 6.9512 14.0192 6.86504 13.6459 6.69274C13.2725 6.52043 12.9818 6.2835 12.7736 5.98196C12.5654 5.68042 12.4613 5.35555 12.4613 5.00734H13.732C13.75 5.31606 13.8667 5.55478 14.082 5.7235C14.2974 5.88863 14.5828 5.97119 14.9382 5.97119C15.2828 5.97119 15.5449 5.90658 15.7244 5.77735C15.9038 5.64452 15.9936 5.47222 15.9936 5.26042Z" :fill="props.fillColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

<template functional>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.4754 6.25948L7 3.73487L9.52616 6.26051L10.9952 4.79152L7 0.796875L3.0064 4.79047L4.4754 6.25948ZM0.5 7.29688L1.969 5.82788L3.438 7.29636L1.969 8.76536L0.5 7.29688ZM4.4754 8.33428L7 10.8589L9.52564 8.33324L10.9957 9.8012L10.9952 9.80224L7 13.7969L3.0064 9.80328L3.00432 9.8012L4.4754 8.33428ZM10.562 7.29739L12.031 5.82839L13.5 7.29739L12.031 8.7664L10.562 7.29739Z"
      :fill="props.fillColor"
    />
    <path
      d="M8.48969 7.2959H8.49021L6.99996 5.80554L5.89813 6.90693L5.77178 7.03329L5.51075 7.29433L5.50867 7.29642L5.51075 7.2985L6.99996 8.78833L8.49073 7.29745L8.49125 7.29642L8.48969 7.2959Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

<template functional>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5288_25)">
      <path
        d="M12.7126 3.62439V10.3521C10.8867 11.6294 8.53964 12.5855 6.81952 14V12.8637C8.40642 11.8253 10.0874 10.8614 11.7448 9.81918V5.28182C10.3773 6.14384 8.9589 6.82954 7.66195 7.6994V9.23144L6.82344 9.64678V7.02545L12.7126 3.62439Z"
        :fill="props.fillColor"
      />
      <path
        d="M6.12205 7.01758V10.5401V11.9664V13.902L0.201538 10.3246V3.59692L1.15368 4.16507V9.79171L5.24436 12.2955V7.58964L3.8808 6.80599V5.72846L6.12205 7.01758Z"
        :fill="props.fillColor"
      />
      <path
        d="M12.3325 3.14246L11.3843 3.71061L10.4086 3.16989L6.48252 1.04226L2.50939 3.17772L6.48252 5.4464L6.77639 5.27008L7.08202 5.10551L7.75596 4.72152L8.74729 5.22698L8.31628 5.47775L6.74896 6.38679L6.45509 6.55528C4.49204 5.41898 2.54073 4.28659 0.58551 3.15029C2.56032 2.04926 4.44502 1.00308 6.43942 0C8.13995 0.783655 9.70727 1.73188 11.3451 2.62524C11.6821 2.80157 12.0073 2.97397 12.3325 3.14246Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5288_25">
        <rect width="12.5111" height="14" fill="white" transform="translate(0.201538)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="10" height="11" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.8889 16.0747L9.12299 11.4278L12.0448 10.3604L12.7715 7.62937L12.7467 7.56162L9.87063 8.61233L11.9428 0.80957H6.06597L3.35599 10.9923L1.09334 11.8189L0.345703 14.6344L2.60662 13.8085L1.00944 19.8097H16.6501L17.6528 16.0747H7.8889Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.4 0.000153787H1.3V4.80015H0V6.00015H1.3V10.8002H4.9C4.9 10.8002 9.5 11.2002 10 6.20015V4.80015C9.6 -0.0998459 5.1 0.000153787 4.4 0.000153787ZM4.8 8.80015H3.3V6.00015H5.5V4.80015H3.3V2.00015H4.7C5.3 2.00015 8 2.20015 8 5.50015C8 8.90015 5.2 8.80015 4.8 8.80015Z"
      fill="#2D4D70"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

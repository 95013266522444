<template>
  <span class="unknownIcon-root">?</span>
</template>

<script>
export default {};
</script>
<style lang="scss">
.unknownIcon-root {
  color: black;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
</style>

<template functional>
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5070_39)">
      <path
        d="M2.44109 7.28141C2.51351 7.20899 2.61308 7.16675 2.71869 7.16675H12.2958C12.4708 7.16675 12.5583 7.37796 12.4346 7.50167L10.5427 9.39355C10.4703 9.46597 10.3707 9.50821 10.2651 9.50821H0.688008C0.513002 9.50821 0.425498 9.297 0.54921 9.17329L2.44109 7.28141Z"
        :fill="props.fillColor"
      />
      <path
        d="M2.44109 0.217809C2.51652 0.145392 2.6161 0.103149 2.71869 0.103149H12.2958C12.4708 0.103149 12.5583 0.314364 12.4346 0.438075L10.5427 2.32995C10.4703 2.40237 10.3707 2.44461 10.2651 2.44461H0.688008C0.513002 2.44461 0.425498 2.2334 0.54921 2.10969L2.44109 0.217809Z"
        :fill="props.fillColor"
      />
      <path
        d="M10.5427 3.72696C10.4703 3.65455 10.3707 3.6123 10.2651 3.6123H0.688008C0.513002 3.6123 0.425498 3.82352 0.54921 3.94723L2.44109 5.83911C2.51351 5.91153 2.61308 5.95377 2.71869 5.95377H12.2958C12.4708 5.95377 12.5583 5.74255 12.4346 5.61884L10.5427 3.72696Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5070_39">
        <rect width="12" height="9.40508" fill="white" transform="translate(0.491882 0.103149)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.785492 7.50744V6.76339C0.785492 5.63988 0.798036 5.0744 0.81685 5.0744C0.848208 5.0744 1.55063 5.89286 2.9241 7.52232L4.98746 9.98512C4.52336 9.97024 3.82721 9.97024 2.89902 9.97024C1.97709 9.97024 1.28722 9.9628 0.823122 9.95536L0.785492 7.50744ZM0.772949 3.35565V0H3.9401C6.07871 0 7.23896 0.0297619 7.40829 0.0892857C8.2675 0.409226 8.83194 1.03423 9.09535 1.9494C9.16434 2.16518 9.19569 2.43304 9.19569 2.74554V3.05804C9.18315 3.37798 9.17061 3.60119 9.15179 3.7128C9.13298 3.8244 9.07654 4.00298 8.97619 4.22619C8.74414 4.79911 8.37412 5.23065 7.85985 5.52083C7.48355 5.73661 6.96928 5.84821 6.32331 5.84821H5.72751C6.90029 7.2247 8.06681 8.60863 9.22705 10H6.36721C4.50455 7.7753 2.64188 5.56548 0.772949 3.35565Z" :fill="props.fillColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

<template functional>
  <svg width="9" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.71429 0H1.42857V4.28571H0V5.71429H1.42857V7.14286H0V8.57143H1.42857V10H2.85714V8.57143H4.28571V7.14286H2.85714V5.71429H5.71429C7.29048 5.71429 8.57143 4.43333 8.57143 2.85714C8.57143 1.28095 7.29048 0 5.71429 0ZM5.71429 4.28571H2.85714V1.42857H5.71429C6.5 1.42857 7.14286 2.07143 7.14286 2.85714C7.14286 3.64286 6.5 4.28571 5.71429 4.28571Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

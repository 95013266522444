<template functional>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5070_197)">
      <path
        d="M10.8004 3.58239C10.5628 3.44662 10.2573 3.44662 9.98575 3.58239L8.08497 4.70249L6.79515 5.41529L4.92831 6.53539C4.69071 6.67116 4.38523 6.67116 4.11369 6.53539L2.65416 5.65288C2.41656 5.51711 2.24685 5.24557 2.24685 4.94009V3.24296C2.24685 2.97142 2.38262 2.69988 2.65416 2.53017L4.11369 1.6816C4.35129 1.54583 4.65677 1.54583 4.92831 1.6816L6.38784 2.56411C6.62544 2.69988 6.79515 2.97142 6.79515 3.2769V4.39701L8.08497 3.65027V2.49623C8.08497 2.22468 7.9492 1.95314 7.67766 1.78343L4.96225 0.188131C4.72466 0.0523612 4.41917 0.0523612 4.14763 0.188131L1.36434 1.81737C1.0928 1.95314 0.957031 2.22468 0.957031 2.49623V5.68683C0.957031 5.95837 1.0928 6.22991 1.36434 6.39962L4.11369 7.99492C4.35129 8.13069 4.65677 8.13069 4.92831 7.99492L6.79515 6.90876L8.08497 6.16202L9.95181 5.07586C10.1894 4.94009 10.4949 4.94009 10.7664 5.07586L12.226 5.92442C12.4636 6.06019 12.6333 6.33173 12.6333 6.63722V8.33435C12.6333 8.60589 12.4975 8.87743 12.226 9.04714L10.8004 9.8957C10.5628 10.0315 10.2573 10.0315 9.98575 9.8957L8.52622 9.04714C8.28862 8.91137 8.11891 8.63983 8.11891 8.33435V7.24818L6.82909 7.99492V9.11502C6.82909 9.38657 6.96486 9.65811 7.2364 9.82782L9.98575 11.4231C10.2234 11.5589 10.5288 11.5589 10.8004 11.4231L13.5497 9.82782C13.7873 9.69205 13.957 9.42051 13.957 9.11502V5.89048C13.957 5.61894 13.8213 5.3474 13.5497 5.17769L10.8004 3.58239Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5070_197">
        <rect width="13" height="11.4386" fill="white" transform="translate(0.957031 0.0863037)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="17" height="7" viewBox="0 0 17 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.29328 4.1665H1.64533V3.21729H3.29328C3.56281 3.21729 3.78107 3.17334 3.94807 3.08545C4.11799 2.99463 4.2425 2.87158 4.3216 2.71631C4.4007 2.55811 4.44025 2.37793 4.44025 2.17578C4.44025 1.97949 4.4007 1.79639 4.3216 1.62646C4.2425 1.45654 4.11799 1.31885 3.94807 1.21338C3.78107 1.10791 3.56281 1.05518 3.29328 1.05518H2.04084V6.5H0.832344V0.101562H3.29328C3.79133 0.101562 4.21613 0.190918 4.5677 0.369629C4.92219 0.54541 5.19172 0.790039 5.37629 1.10352C5.56379 1.41406 5.65754 1.76855 5.65754 2.16699C5.65754 2.58008 5.56379 2.93604 5.37629 3.23486C5.19172 3.53369 4.92219 3.76367 4.5677 3.9248C4.21613 4.08594 3.79133 4.1665 3.29328 4.1665ZM7.20459 0.101562L8.4834 2.38232L9.7666 0.101562H11.1553L9.25684 3.27441L11.2036 6.5H9.80176L8.4834 4.17529L7.16943 6.5H5.76318L7.70996 3.27441L5.81152 0.101562H7.20459ZM14.5129 0.101562V6.5H13.3088V0.101562H14.5129ZM16.4992 0.101562V1.05518H11.3444V0.101562H16.4992Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>

<template functional>
  <svg width="8" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 8.56095L7 6.57005L3.5 10.9501L0 6.57005L3.5 8.56095ZM7 4.97732L3.50004 3.48413L0 5.07686L3.50004 0L7 4.97732ZM0 5.80664L3.49996 4.30408L7 5.80664L3.49996 7.84862L0 5.80664Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

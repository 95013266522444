export { default as usdIcon } from '@/assets/icons/currencies/USD';
export { default as uahIcon } from '@/assets/icons/currencies/UAH';
export { default as rubIcon } from '@/assets/icons/currencies/RUB';
export { default as eurIcon } from '@/assets/icons/currencies/EUR';

export { default as btcIcon } from '@/assets/icons/currencies/BTC';
export { default as ltcIcon } from '@/assets/icons/currencies/LTC';
export { default as ethIcon } from '@/assets/icons/currencies/ETH';
export { default as usdtIcon } from '@/assets/icons/currencies/USDT';
export { default as dogeIcon } from '@/assets/icons/currencies/DOGE';
export { default as adaIcon } from '@/assets/icons/currencies/ADA';
export { default as bnbIcon } from '@/assets/icons/currencies/BNB';
export { default as tronIcon } from '@/assets/icons/currencies/TRON';
export { default as trxIcon } from '@/assets/icons/currencies/TRX';
export { default as usdtomniIcon } from '@/assets/icons/currencies/USDT';
export { default as rsdIcon } from '@/assets/icons/currencies/RSD';
export { default as kztIcon } from '@/assets/icons/currencies/KZT';
export { default as uzsIcon } from '@/assets/icons/currencies/UZS';
export { default as unknownIcon } from '@/assets/icons/currencies/UNKNOWN';

// 

export { default as xrpIcon } from '@/assets/icons/currencies/XRP';
export { default as solIcon } from '@/assets/icons/currencies/SOL';
export { default as dotIcon } from '@/assets/icons/currencies/DOT';
export { default as maticIcon } from '@/assets/icons/currencies/MATIC';
export { default as shibIcon } from '@/assets/icons/currencies/SHIB';

// 

export { default as pxtIcon } from '@/assets/icons/currencies/PXT';
export { default as ilvIcon } from '@/assets/icons/currencies/ILV';
export { default as galaIcon } from '@/assets/icons/currencies/GALA';
export { default as axsIcon } from '@/assets/icons/currencies/AXS';
export { default as gafiIcon } from '@/assets/icons/currencies/GAFI';
export { default as nearIcon } from '@/assets/icons/currencies/NEAR';

// 

export { default as cadIcon } from '@/assets/icons/currencies/UNKNOWN';
export { default as avaxIcon } from '@/assets/icons/currencies/UNKNOWN';
export { default as lunaIcon } from '@/assets/icons/currencies/UNKNOWN';

<template functional>
  <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.43714 5.9751L2.28782 7.19407V10.3564H0.525534V0.214569H2.28782V4.97206L3.263 3.76701L6.23034 0.214569H8.36181L4.59343 4.70736L8.57774 10.3564H6.48807L3.43714 5.9751Z" :fill="props.fillColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>

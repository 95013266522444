<template functional>
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5070_51)">
      <path
        d="M10.7339 0.836914H12.4694L8.85824 4.41061C7.55099 5.70441 5.43193 5.70441 4.12468 4.41061L0.514027 0.836914H2.2495L4.99266 3.55175C5.82032 4.37124 7.16261 4.37124 7.99074 3.55175L10.7339 0.836914ZM2.22742 10.7745H0.491943L4.12516 7.17873C5.43241 5.88492 7.55147 5.88492 8.85872 7.17873L12.4919 10.7745H10.7565L7.99074 8.03759C7.16309 7.2181 5.8208 7.2181 4.99266 8.03759L2.22742 10.7745Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5070_51">
        <rect width="12" height="9.93759" fill="white" transform="translate(0.491943 0.836914)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2D4D70',
    },
  },
};
</script>
